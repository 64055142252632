.Toastify__toast-container {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  padding: 4rem 2rem;
}

.Toastify__toast {
  align-items: center;
  border-radius: 5px;
  display: inline-flex;
  min-height: 5rem;
}

.Toastify__toast--error {
  background: #ef5e72;
  box-shadow: none;
}
.Toastify__toast--success {
  background: #00cd8d;
  box-shadow: 0px 2px 5px #a7e1cf;
}
.Toastify__toast-body {
  align-items: center;
  display: flex;
  font-family: 'Helvetica Neue', sans-serif;
  font-size: 1.5rem;
  font-weight: normal;
  justify-content: space-between;
  padding: 0 1rem;
}

.Toastify__toast-body span {
  font-size: 1.5rem;
  font-weight: normal;
}
.Toastify__toast-body svg {
  display: none;
}

.Toastify__close-button {
  align-self: center;
  opacity: 0.4;
}
